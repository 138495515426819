import React from 'react';
import styled from 'styled-components';

import useSite from '../hooks/use-site';
import useCompany from '../hooks/use-company';

const Maps = ({ className }) => {
  const { googleMapsApiKey } = useSite();

  const {
    name,
    street,
    houseNumber,
    city,
  } = useCompany();

  return (
    <iframe
      className={className}
      title="maps"
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(`${name},${street} ${houseNumber},${city}`)}&key=${googleMapsApiKey}`}
      allowFullScreen
      referrerPolicy="origin"
    />
  );
};

export default styled(Maps)`
  display: block;
  width: 100%;
  height: 16rem;
`;
