import React from 'react';
import { graphql } from 'gatsby';
import Maps from 'gatsby-theme-core/src/components/maps';
import Page from './page';

export const pageQuery = graphql`
  query EaContactPageById($id: String!) {
    page: strapiPagesPages(id: { eq: $id }) {
      id
      title
      content: childStrapiPagesPagesContent {
        childMarkdownRemark {
          html
        }
      }
      form {
        ...StrapiForm
      }
      image {
        url
        height
        width
        name
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH,
              quality: 100,
              placeholder: BLURRED,
              breakpoints: [576, 768, 992, 1200, 2400],
            )
          }
        }
      }
      contentImage {
        url
        height
        width
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
      ...PagesPageHead
    }
  }
`;

const EaContactPage = (props) => (
  <Page {...props} disablePageImage>
    <Maps />
  </Page>
);

export default EaContactPage;
